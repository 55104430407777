import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import { Link } from "gatsby";
import ContentWrapper from "../components/ContentWrapper";

const MainWrapper = styled.div`
  padding-top: 65px;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding-top: 63px;
  }
  .breadcrumbs {
    background: #ececec;
    padding: 19px 0;
    min-height: 47px;
  }
  .breadcrumbs-list {
    font-size: 14px;
    line-height: 1;
  }
  .breadcrumbs-item {
    float: left;
    margin: -2px 6px 0 0;
    &:last-child {
      &::before {
        content: ">";
        padding: 0 5px 0 0;
      }
    }
  }
  .breadcrumbs-item-link {
    color: #555;
    &:hover {
      text-decoration: underline;
    }
  }
  .works-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin: 30px 0;
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      grid-template-columns: 1fr!important;
    }
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      margin: 15px 0;
    }
  }
  .works-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .works-year {
    background: #2a2a2a;
    position: absolute;
    font-size: 12px;
    padding: 8px 12px;
    line-height: 1;
    font-weight: bold;
    color: #fff;
  }
`;

const Works = () => {
  return (
    <Layout>
      <Seo title="施工事例" description="株式会社八木造園土木の施工事例ページです。" />
      <MainWrapper>
        <div className="breadcrumbs">
          <ContentWrapper>
            <ol className="breadcrumbs-list">
              <li className="breadcrumbs-item">
                <Link to={`/`} className="breadcrumbs-item-link">ホーム</Link>
              </li>
              <li className="breadcrumbs-item">
                <Link to={`/works`} className="breadcrumbs-item-link">施工事例</Link>
              </li>
            </ol>
          </ContentWrapper>
        </div>
        <ContentWrapper>
          <div className="works-content">
            <div>
              <span className="works-year">令和3年度</span>
              <img src="/images/works-yamamototei.jpg" alt="yamamototei" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和3年度</span>
              <img src="/images/works-seitokuji.jpg" alt="seitokuji" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和2年度</span>
              <img src="/images/works-ganjyojigawa.jpg" alt="ganjyojigawa" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和2年度</span>
              <img src="/images/works-harakawa.jpg" alt="harakawa" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和2年度</span>
              <img src="/images/works-katuragawa.jpg" alt="katuragawa" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和2年度</span>
              <img src="/images/works-higasisyogawa.jpg" alt="higasisyogawa" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和2年度</span>
              <img src="/images/works-matuokataku.jpg" alt="matuokataku" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和2年度</span>
              <img src="/images/works-ishiisyokuhin.jpg" alt="ishiisyokuhin" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和2年度</span>
              <img src="/images/works-ikegamitiku.jpg" alt="ikegamitiku" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和元年度</span>
              <img src="/images/works-yuragawa.jpg" alt="yuragawa" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和元年度</span>
              <img src="/images/works-katuragawa-sonobe.jpg" alt="katuragawa-sonobe" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和元年度</span>
              <img src="/images/works-kyoto-taisyakuten.jpg" alt="kyoto-taisyakuten" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和元年度</span>
              <img src="/images/works-ooutisama.jpg" alt="ooutisama" className="works-img" />
            </div>
            <div>
              <span className="works-year">令和元年度</span>
              <img src="/images/works-ikegamitiku2.jpg" alt="ikegamitiku2" className="works-img" />
            </div>
            <div>
              <span className="works-year">平成30年度</span>
              <img src="/images/works-sityowatisen.jpg" alt="sityowatisen" className="works-img" />
            </div>
            <div>
              <span className="works-year">平成30年度</span>
              <img src="/images/works-omatiyagawa.jpg" alt="omatiyagawa" className="works-img" />
            </div>
            <div>
              <span className="works-year">平成30年度</span>
              <img src="/images/works-sibayama.jpg" alt="sibayama" className="works-img" />
            </div>
            <div>
              <span className="works-year">平成30年度</span>
              <img src="/images/works-kagamigawa.jpg" alt="kagamigawa" className="works-img" />
            </div>
            <div>
              <span className="works-year">平成30年度</span>
              <img src="/images/works-kobayashitei.jpg" alt="kobayashitei" className="works-img" />
            </div>
            <div>
              <span className="works-year">平成30年度</span>
              <img src="/images/works-harimatei.jpg" alt="harimatei" className="works-img" />
            </div>
            <div>
              <span className="works-year">平成30年度</span>
              <img src="/images/works-yagitei.jpg" alt="yagitei" className="works-img" />
            </div>
          </div>
        </ContentWrapper>
      </MainWrapper>
    </Layout>
  );
};

export default Works;